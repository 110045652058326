import React from 'react';
import AIAgentsDirectory from './components/AIAgentsDirectory';
import SEOHead from './components/SEOHead';
import StructuredData from './components/StructuredData';
import './App.css';

function App() {
  return (
    <>
      <SEOHead />
      <StructuredData />
      <div className="App">
        <AIAgentsDirectory />
      </div>
    </>
  );
}

export default App;
