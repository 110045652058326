import React from 'react';

const StructuredData = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebApplication",
    "name": "Top AI Dev Tools",
    "applicationCategory": "DeveloperApplication",
    "description": "Compare and find the best AI-powered development tools and coding assistants",
    "operatingSystem": "Any",
    "offers": {
      "@type": "AggregateOffer",
      "offers": [
        {
          "@type": "Offer",
          "name": "Free Tools",
          "description": "AI development tools with free tiers"
        },
        {
          "@type": "Offer",
          "name": "Premium Tools",
          "description": "Professional AI coding assistants"
        }
      ]
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "ratingCount": "1250"
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
};

export default StructuredData;
