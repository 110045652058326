import React, { useState, useMemo } from 'react';
import { Search, Star, ExternalLink, Code, Filter, X, Zap, Lock, Server, Globe } from 'lucide-react';

const data = {
  metadata: {
    lastUpdated: "2024-02-20",
    totalAgents: 7
  },
  categories: [
    "Code Generation",
    "Code Review",
    "Documentation",
    "Testing",
    "DevOps",
    "Security",
    "IDE Integration",
    "API Development"
  ],
  agents: [
    {
      id: "claude-3",
      name: "Claude 3",
      provider: "Anthropic",
      description: "Advanced AI assistant with deep understanding of code, strong reasoning abilities, and detailed technical explanations",
      usageCount: 71,
      rating: 4.8,
      releaseDate: "2024-02",
      pricing: {
        type: "API-based",
        hasFreeVersion: false,
        startingPrice: "$4/million tokens",
        details: [
          "Pay per token used",
          "No monthly commitment",
          "Volume discounts available"
        ]
      },
      strengths: [
        "Exceptional at understanding complex codebases",
        "Detailed technical explanations",
        "Accurate code analysis",
        "Strong security focus"
      ],
      limitations: [
        "No direct IDE integration",
        "Requires API integration",
        "No offline mode"
      ],
      features: [
        "Multi-language support",
        "Code explanation",
        "Bug detection",
        "Test generation",
        "Documentation writing",
        "Architecture suggestions",
        "Security analysis"
      ],
      bestFor: [
        "Complex code analysis",
        "Technical documentation",
        "Code review",
        "Learning programming concepts"
      ],
      languages: [
        "Python",
        "JavaScript",
        "Java",
        "C++",
        "Ruby",
        "Go",
        "Rust",
        "PHP"
      ],
      integrations: [
        "Custom API integration",
        "VS Code via extensions",
        "JetBrains IDEs via plugins"
      ],
      tags: ["Code Generation", "Documentation", "Testing"],
      links: {
        website: "https://anthropic.com",
        documentation: "https://docs.anthropic.com",
        pricing: "https://anthropic.com/pricing"
      }
    },
    {
      id: "github-copilot",
      name: "GitHub Copilot",
      provider: "GitHub/Microsoft",
      description: "AI pair programmer that integrates directly with your IDE for real-time code suggestions and completions",
      usageCount: 1200000,
      rating: 4.7,
      releaseDate: "2023-07",
      pricing: {
        type: "Subscription",
        hasFreeVersion: false,
        startingPrice: "$10/month",
        details: [
          "Monthly/yearly subscription",
          "Business plans available",
          "Free for students"
        ]
      },
      strengths: [
        "Seamless IDE integration",
        "Real-time suggestions",
        "Works with multiple languages",
        "Large training dataset"
      ],
      limitations: [
        "May suggest outdated code",
        "Requires internet connection",
        "Limited context understanding"
      ],
      features: [
        "Real-time code completion",
        "Context-aware suggestions",
        "Multiple IDE support",
        "Natural language to code",
        "Test generation",
        "Documentation help"
      ],
      bestFor: [
        "Daily coding tasks",
        "Rapid prototyping",
        "Boilerplate code",
        "Learning new frameworks"
      ],
      languages: [
        "JavaScript",
        "Python",
        "Java",
        "Go",
        "Ruby",
        "PHP",
        "C#",
        "TypeScript"
      ],
      integrations: [
        "VS Code",
        "Visual Studio",
        "JetBrains IDEs",
        "Neovim"
      ],
      tags: ["IDE Integration", "Code Generation"],
      links: {
        website: "https://github.com/features/copilot",
        documentation: "https://docs.github.com/copilot",
        pricing: "https://github.com/features/copilot#pricing"
      }
    },
    {
      id: "codewhisperer",
      name: "Amazon CodeWhisperer",
      provider: "Amazon",
      description: "Security-focused AI coding companion with deep AWS integration and real-time vulnerability scanning",
      usageCount: 58000,
      rating: 4.6,
      releaseDate: "2023-11",
      pricing: {
        type: "Freemium",
        hasFreeVersion: true,
        startingPrice: "Free",
        details: [
          "Free Individual tier",
          "Professional features $19/month",
          "Enterprise pricing available"
        ]
      },
      strengths: [
        "Strong AWS integration",
        "Built-in security scanning",
        "Free tier available",
        "Reference tracking"
      ],
      limitations: [
        "Best for AWS ecosystem",
        "Smaller training dataset",
        "Limited community resources"
      ],
      features: [
        "Security scanning",
        "AWS integration",
        "Code completion",
        "Reference tracking",
        "Vulnerability detection",
        "API suggestions"
      ],
      bestFor: [
        "AWS development",
        "Security-conscious teams",
        "Enterprise development",
        "Cloud applications"
      ],
      languages: [
        "Python",
        "Java",
        "JavaScript",
        "C#",
        "TypeScript",
        "Ruby",
        "Go"
      ],
      integrations: [
        "VS Code",
        "JetBrains IDEs",
        "AWS Cloud9",
        "AWS Lambda Console"
      ],
      tags: ["Security", "IDE Integration", "Cloud Development"],
      links: {
        website: "https://aws.amazon.com/codewhisperer",
        documentation: "https://docs.aws.amazon.com/codewhisperer",
        pricing: "https://aws.amazon.com/codewhisperer/pricing"
      }
    },
    {
      id: "tabnine",
      name: "Tabnine",
      provider: "Tabnine",
      description: "Self-hosted capable AI coding assistant with strong privacy focus and team collaboration features",
      usageCount: 45000,
      rating: 4.5,
      releaseDate: "2023-09",
      pricing: {
        type: "Tiered",
        hasFreeVersion: true,
        startingPrice: "Free",
        details: [
          "Free basic features",
          "Pro: $12/month",
          "Enterprise: Custom pricing",
          "Self-hosted option available"
        ]
      },
      strengths: [
        "Privacy-focused",
        "Self-hosting option",
        "Works offline",
        "Team collaboration"
      ],
      limitations: [
        "Smaller language model",
        "Limited to code completion",
        "Less documentation support"
      ],
      features: [
        "Code completion",
        "Team sharing",
        "Custom model training",
        "Offline support",
        "Multi-repo learning"
      ],
      bestFor: [
        "Privacy-conscious teams",
        "Enterprise development",
        "Offline development",
        "Team collaboration"
      ],
      languages: [
        "JavaScript",
        "Python",
        "Java",
        "C++",
        "PHP",
        "Ruby",
        "Go",
        "Rust"
      ],
      integrations: [
        "VS Code",
        "IntelliJ",
        "PyCharm",
        "WebStorm"
      ],
      tags: ["Code Generation", "Privacy", "Team Collaboration"],
      links: {
        website: "https://www.tabnine.com",
        documentation: "https://docs.tabnine.com",
        pricing: "https://www.tabnine.com/pricing"
        }
      },
{
  id: "cursor",
  name: "Cursor",
  provider: "Cursor Inc.",
  description: "AI-native code editor with built-in intelligence for editing, debugging, and understanding code",
  usageCount: 35000,
  rating: 4.6,
  releaseDate: "2023-10",
  pricing: {
    type: "Freemium",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free for individuals",
      "Pro: $20/month",
      "Team plans available",
      "Custom enterprise pricing"
    ]
  },
  strengths: [
    "Built-in AI code editor",
    "Fast codebase understanding",
    "Natural language code editing",
    "Terminal integration"
  ],
  limitations: [
    "Relatively new platform",
    "Limited ecosystem",
    "Learning curve for editor",
    "Some features Pro-only"
  ],
  features: [
    "AI code editing",
    "Natural language commands",
    "Code explanation",
    "Terminal integration",
    "Git integration",
    "Multi-file search",
    "Code navigation"
  ],
  bestFor: [
    "Full-stack development",
    "Code exploration",
    "Quick prototyping",
    "Learning new codebases"
  ],
  languages: [
    "JavaScript",
    "TypeScript",
    "Python",
    "Go",
    "Rust",
    "Ruby",
    "PHP"
  ],
  integrations: [
    "Built-in editor",
    "GitHub",
    "Terminal",
    "Custom extensions"
  ],
  tags: ["Code Generation", "IDE Integration", "Code Exploration"],
  links: {
    website: "https://cursor.sh",
    documentation: "https://cursor.sh/docs",
    pricing: "https://cursor.sh/pricing"
  }
},
{
  id: "codeium",
  name: "Codeium",
  provider: "Exafunction",
  description: "Free AI coding assistant with enterprise-grade features and strong privacy focus",
  usageCount: 100000,
  rating: 4.5,
  releaseDate: "2023-06",
  pricing: {
    type: "Freemium",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free for individuals",
      "Enterprise: Custom pricing",
      "Self-hosted options",
      "Unlimited usage"
    ]
  },
  strengths: [
    "Completely free for individuals",
    "Privacy-focused",
    "Self-hosted option",
    "Fast completions"
  ],
  limitations: [
    "Less advanced than some paid options",
    "Limited enterprise features in free tier",
    "Smaller community",
    "Basic documentation"
  ],
  features: [
    "Code completion",
    "Natural language to code",
    "Code explanation",
    "Chat interface",
    "Multi-language support",
    "Privacy controls"
  ],
  bestFor: [
    "Individual developers",
    "Startups",
    "Privacy-conscious teams",
    "Students"
  ],
  languages: [
    "Python",
    "JavaScript",
    "Java",
    "C++",
    "Go",
    "Ruby",
    "PHP",
    "Rust"
  ],
  integrations: [
    "VS Code",
    "JetBrains IDEs",
    "Vim",
    "Emacs",
    "Cloud IDEs"
  ],
  tags: ["Code Generation", "Privacy", "Free Tools"],
  links: {
    website: "https://codeium.com",
    documentation: "https://codeium.com/documentation",
    pricing: "https://codeium.com/pricing"
  }
},
{
  id: "sourcegraph-cody",
  name: "Sourcegraph Cody",
  provider: "Sourcegraph",
  description: "Context-aware AI assistant specialized in large codebase understanding and enterprise development",
  usageCount: 25000,
  rating: 4.4,
  releaseDate: "2023-12",
  pricing: {
    type: "Tiered",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free for personal use",
      "Pro: $9/month",
      "Enterprise: Custom pricing",
      "Self-hosted available"
    ]
  },
  strengths: [
    "Excellent code search",
    "Enterprise focus",
    "Deep codebase understanding",
    "Strong privacy features"
  ],
  limitations: [
    "Complex setup for enterprises",
    "Premium features costly",
    "Requires setup time",
    "Learning curve"
  ],
  features: [
    "Code search",
    "Code navigation",
    "Intelligence sharing",
    "Code generation",
    "Code explanations",
    "Enterprise integration"
  ],
  bestFor: [
    "Enterprise development",
    "Large codebases",
    "Team collaboration",
    "Code discovery"
  ],
  languages: [
    "JavaScript",
    "Python",
    "Java",
    "Go",
    "C++",
    "C#",
    "Ruby",
    "TypeScript"
  ],
  integrations: [
    "VS Code",
    "JetBrains IDEs",
    "GitHub",
    "GitLab",
    "Bitbucket"
  ],
  tags: ["Enterprise", "Code Search", "Team Collaboration"],
  links: {
    website: "https://sourcegraph.com/cody",
    documentation: "https://docs.sourcegraph.com/cody",
    pricing: "https://sourcegraph.com/pricing"
  }
},
{
  id: "bloop",
  name: "Bloop",
  provider: "Bloop",
  description: "Fast, local-first AI code search and understanding tool with natural language queries",
  usageCount: 15000,
  rating: 4.3,
  releaseDate: "2023-11",
  pricing: {
    type: "Freemium",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free tier available",
      "Pro: $15/month",
      "Team: Custom pricing",
      "Local processing option"
    ]
  },
  strengths: [
    "Natural language search",
    "Local processing",
    "Fast performance",
    "Privacy-focused"
  ],
  limitations: [
    "Newer platform",
    "Limited integrations",
    "Resource intensive",
    "Some features in beta"
  ],
  features: [
    "Natural language search",
    "Local processing",
    "Code understanding",
    "Fast indexing",
    "Cross-repository search",
    "Semantic code search"
  ],
  bestFor: [
    "Code exploration",
    "Legacy code understanding",
    "Local development",
    "Privacy-focused teams"
  ],
  languages: [
    "Python",
    "JavaScript",
    "Java",
    "Go",
    "Rust",
    "C++",
    "TypeScript"
  ],
  integrations: [
    "Standalone app",
    "VS Code",
    "GitHub",
    "GitLab"
  ],
  tags: ["Code Search", "Privacy", "Local Processing"],
  links: {
    website: "https://bloop.ai",
    documentation: "https://bloop.ai/docs",
    pricing: "https://bloop.ai/pricing"
  }
},
{
  id: "intellicode",
  name: "IntelliCode",
  provider: "Microsoft",
  description: "AI-powered code completions and insights integrated deeply with Visual Studio",
  usageCount: 500000,
  rating: 4.4,
  releaseDate: "2023-08",
  pricing: {
    type: "Free",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Included with Visual Studio",
      "Free for all users",
      "Enterprise features included",
      "No premium tier"
    ]
  },
  strengths: [
    "Deep VS integration",
    "Team model training",
    "Microsoft ecosystem",
    "Free to use"
  ],
  limitations: [
    "Visual Studio focused",
    "Limited outside VS",
    "Basic completions",
    "Microsoft-centric"
  ],
  features: [
    "Code completions",
    "Pattern recognition",
    "Team completions",
    "API usage examples",
    "Argument completion",
    "Style inference"
  ],
  bestFor: [
    "Visual Studio users",
    ".NET development",
    "Microsoft ecosystem",
    "Enterprise teams"
  ],
  languages: [
    "C#",
    "XAML",
    "JavaScript",
    "TypeScript",
    "Python",
    "SQL",
    "Java"
  ],
  integrations: [
    "Visual Studio",
    "VS Code",
    "Azure",
    "GitHub"
  ],
  tags: ["IDE Integration", "Microsoft", "Enterprise"],
  links: {
    website: "https://visualstudio.microsoft.com/services/intellicode",
        documentation: "https://learn.microsoft.com/visualstudio/intellicode",
        pricing: "https://visualstudio.microsoft.com/pricing"
      }
    },
    {
  id: "phind",
  name: "Phind",
  provider: "Phind Inc.",
  description: "AI coding assistant with integrated web search and real-time code generation, specializing in up-to-date technical solutions",
  usageCount: 250000,
  rating: 4.7,
  releaseDate: "2023-09",
  pricing: {
    type: "Freemium",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free tier with generous limits",
      "Pro: $25/month",
      "Team plans available",
      "Custom enterprise pricing"
    ]
  },
  strengths: [
    "Real-time web search integration",
    "Up-to-date coding solutions",
    "Detailed explanations",
    "Accurate code generation"
  ],
  limitations: [
    "Limited IDE integration",
    "Web interface primarily",
    "Pro features can be costly",
    "No offline mode"
  ],
  features: [
    "Integrated web search",
    "Code generation",
    "Technical explanations",
    "Solution verification",
    "Multi-turn conversations",
    "Documentation help",
    "Error fixing"
  ],
  bestFor: [
    "Problem solving",
    "Learning programming",
    "Finding current solutions",
    "Technical research"
  ],
  languages: [
    "Python",
    "JavaScript",
    "TypeScript",
    "Java",
    "C++",
    "Go",
    "Rust",
    "PHP"
  ],
  integrations: [
    "Web interface",
    "Chrome extension",
    "VS Code (beta)",
    "API access"
  ],
  tags: ["Code Generation", "Technical Research", "Learning"],
  links: {
    website: "https://phind.com",
    documentation: "https://docs.phind.com",
    pricing: "https://phind.com/pricing"
  }
},
{
  id: "replit-ghostwriter",
  name: "Replit Ghost Writer",
  provider: "Replit",
  description: "Integrated AI coding assistant built directly into Replit's online IDE with real-time collaboration features",
  usageCount: 185000,
  rating: 4.5,
  releaseDate: "2023-07",
  pricing: {
    type: "Subscription",
    hasFreeVersion: false,
    startingPrice: "$10/month",
    details: [
      "Included with Replit Pro",
      "Team plans available",
      "Educational discounts",
      "Enterprise options"
    ]
  },
  strengths: [
    "Seamless Replit integration",
    "Real-time collaboration",
    "Educational focus",
    "Instant deployment"
  ],
  limitations: [
    "Requires Replit platform",
    "Internet connection needed",
    "Limited to web IDE",
    "Basic completions"
  ],
  features: [
    "Code completion",
    "Real-time suggestions",
    "Multi-user collaboration",
    "Inline documentation",
    "Debug assistance",
    "Project templates",
    "Git integration"
  ],
  bestFor: [
    "Online development",
    "Educational coding",
    "Team projects",
    "Quick prototypes"
  ],
  languages: [
    "Python",
    "JavaScript",
    "HTML/CSS",
    "Java",
    "C++",
    "Ruby",
    "PHP",
    "Go"
  ],
  integrations: [
    "Replit IDE",
    "GitHub",
    "Google Drive",
    "Educational LMS"
  ],
  tags: ["IDE Integration", "Education", "Collaboration"],
  links: {
    website: "https://replit.com/ghostwriter",
    documentation: "https://docs.replit.com/ghostwriter",
    pricing: "https://replit.com/pricing"
  }
},
{
  id: "gpt-pilot",
  name: "GPT Pilot",
  provider: "pythagora-io",
  description: "Open-source autonomous code generation system that can plan, code, and deploy entire applications with minimal human input",
  usageCount: 45000,
  rating: 4.4,
  releaseDate: "2023-10",
  pricing: {
    type: "Open Source",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Open source - self host",
      "Cloud version coming soon",
      "Enterprise support available",
      "Custom development options"
    ]
  },
  strengths: [
    "Full project generation",
    "Autonomous development",
    "Customizable workflows",
    "Open source flexibility"
  ],
  limitations: [
    "Technical setup required",
    "API costs for models",
    "Experimental technology",
    "Learning curve"
  ],
  features: [
    "Project planning",
    "Autonomous coding",
    "Testing generation",
    "Documentation writing",
    "Architecture design",
    "Dependency management",
    "Error handling"
  ],
  bestFor: [
    "Full-stack projects",
    "MVP development",
    "Proof of concepts",
    "Learning system design"
  ],
  languages: [
    "Python",
    "JavaScript",
    "TypeScript",
    "HTML/CSS",
    "SQL",
    "Docker",
    "YAML"
  ],
  integrations: [
    "VS Code",
    "GitHub",
    "Docker",
    "Various LLMs"
  ],
  tags: ["Autonomous Development", "Project Generation", "Open Source"],
  links: {
    website: "https://gpt-pilot.ai",
    documentation: "https://docs.gpt-pilot.ai",
    pricing: "https://gpt-pilot.ai/pricing"
  }
},
{
  id: "askcodi",
  name: "AskCodi",
  provider: "AskCodi",
  description: "AI code companion focused on explanation and educational aspects, with strong documentation and testing capabilities",
  usageCount: 75000,
  rating: 4.3,
  releaseDate: "2023-08",
  pricing: {
    type: "Freemium",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free tier available",
      "Pro: $15/month",
      "Team: $49/month",
      "Enterprise custom pricing"
    ]
  },
  strengths: [
    "Detailed explanations",
    "Educational focus",
    "Test generation",
    "Documentation expertise"
  ],
  limitations: [
    "Limited code generation",
    "Basic IDE integration",
    "Slower response times",
    "Small community"
  ],
  features: [
    "Code explanation",
    "Test generation",
    "Documentation writing",
    "Bug detection",
    "Learning resources",
    "Code review",
    "Best practices"
  ],
  bestFor: [
    "Learning programming",
    "Code understanding",
    "Documentation needs",
    "Test creation"
  ],
  languages: [
    "Python",
    "JavaScript",
    "Java",
    "C#",
    "Ruby",
    "PHP",
    "Go",
    "SQL"
  ],
  integrations: [
    "VS Code",
    "JetBrains IDEs",
    "Web interface",
    "Chrome extension"
  ],
  tags: ["Education", "Documentation", "Testing"],
  links: {
    website: "https://askcodi.com",
    documentation: "https://docs.askcodi.com",
    pricing: "https://askcodi.com/pricing"
  }
},
{
  id: "dash-ai",
  name: "Dash AI",
  provider: "Dash AI Inc.",
  description: "Specialized AI coding assistant focused on debugging and code quality improvement with real-time error detection",
  usageCount: 95000,
  rating: 4.5,
  releaseDate: "2023-11",
  pricing: {
    type: "Tiered",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free tier with limits",
      "Professional: $19/month",
      "Team: $49/user/month",
      "Enterprise available"
    ]
  },
  strengths: [
    "Advanced debugging",
    "Real-time error detection",
    "Performance optimization",
    "Security scanning"
  ],
  limitations: [
    "Focused feature set",
    "Premium features costly",
    "Limited languages",
    "New platform"
  ],
  features: [
    "Automated debugging",
    "Code optimization",
    "Security analysis",
    "Performance profiling",
    "Error prediction",
    "Fix suggestions",
    "Code quality metrics"
  ],
  bestFor: [
    "Debug assistance",
    "Code optimization",
    "Security checks",
    "Quality improvement"
  ],
  languages: [
    "Python",
    "JavaScript",
    "TypeScript",
    "Java",
    "C++",
    "Ruby",
    "Go"
  ],
  integrations: [
    "VS Code",
    "JetBrains IDEs",
    "GitHub",
    "GitLab"
  ],
  tags: ["Debugging", "Code Quality", "Security"],
  links: {
    website: "https://dash.ai",
    documentation: "https://docs.dash.ai",
    pricing: "https://dash.ai/pricing"
  }
},
    {
  id: "gemini-code-assist",
  name: "Gemini Code Assist",
  provider: "Google",
  description: "Enterprise-grade AI coding assistant integrated with Google Cloud, specializing in large-scale development and cloud-native applications",
  usageCount: 850000,
  rating: 4.7,
  releaseDate: "2023-12",
  pricing: {
    type: "Subscription",
    hasFreeVersion: false,
    startingPrice: "$15/user/month",
    details: [
      "Part of Google Cloud subscription",
      "Enterprise pricing available",
      "Volume discounts",
      "Custom contracts for large teams"
    ]
  },
  strengths: [
    "Deep Google Cloud integration",
    "Enterprise-grade security",
    "Cloud-native expertise",
    "Large language support"
  ],
  limitations: [
    "Requires Google Cloud",
    "Premium pricing",
    "Cloud-focused",
    "Limited offline capabilities"
  ],
  features: [
    "Code completion",
    "Code generation",
    "Security scanning",
    "Cloud optimization",
    "Documentation generation",
    "Unit test creation",
    "Code review assistance"
  ],
  bestFor: [
    "Google Cloud development",
    "Enterprise teams",
    "Cloud-native apps",
    "Large-scale projects"
  ],
  languages: [
    "Python",
    "Java",
    "Go",
    "JavaScript",
    "TypeScript",
    "C++",
    "Kotlin",
    "Ruby"
  ],
  integrations: [
    "Google Cloud Console",
    "VS Code",
    "JetBrains IDEs",
    "Cloud Shell Editor"
  ],
  tags: ["Cloud Development", "Enterprise", "Code Generation"],
  links: {
    website: "https://cloud.google.com/code/docs/gemini",
    documentation: "https://cloud.google.com/code/docs",
    pricing: "https://cloud.google.com/pricing"
  }
},
{
  id: "jetbrains-ai",
  name: "JetBrains AI Assistant",
  provider: "JetBrains",
  description: "Native AI coding assistant integrated across all JetBrains IDEs with deep language-specific understanding",
  usageCount: 400000,
  rating: 4.6,
  releaseDate: "2023-09",
  pricing: {
    type: "Subscription",
    hasFreeVersion: false,
    startingPrice: "$10/month",
    details: [
      "Included with JetBrains subscription",
      "Standalone subscription available",
      "Team packages available",
      "Enterprise licensing"
    ]
  },
  strengths: [
    "Native IDE integration",
    "Language-specific features",
    "Smart context awareness",
    "Cross-IDE support"
  ],
  limitations: [
    "JetBrains IDE required",
    "Subscription needed",
    "Limited web interface",
    "Platform dependent"
  ],
  features: [
    "Smart code completion",
    "Code explanation",
    "Refactoring suggestions",
    "Documentation writing",
    "Bug detection",
    "Test generation",
    "Code review"
  ],
  bestFor: [
    "JetBrains IDE users",
    "Professional developers",
    "Team development",
    "Language-specific work"
  ],
  languages: [
    "Java",
    "Kotlin",
    "Python",
    "JavaScript",
    "TypeScript",
    "Go",
    "Ruby",
    "PHP"
  ],
  integrations: [
    "IntelliJ IDEA",
    "PyCharm",
    "WebStorm",
    "All JetBrains IDEs"
  ],
  tags: ["IDE Integration", "Code Generation", "Development Tools"],
  links: {
    website: "https://www.jetbrains.com/ai/",
    documentation: "https://www.jetbrains.com/help/ai/",
    pricing: "https://www.jetbrains.com/ai/buy/"
  }
},
{
  id: "watsonx-code-assistant",
  name: "watsonx Code Assistant",
  provider: "IBM",
  description: "Enterprise AI coding assistant specializing in legacy code modernization and large-scale system development",
  usageCount: 200000,
  rating: 4.5,
  releaseDate: "2023-10",
  pricing: {
    type: "Enterprise",
    hasFreeVersion: false,
    startingPrice: "$35/user/month",
    details: [
      "Enterprise licensing",
      "Custom pricing",
      "Volume discounts",
      "Professional services available"
    ]
  },
  strengths: [
    "Legacy code expertise",
    "Enterprise integration",
    "Security compliance",
    "Mainframe support"
  ],
  limitations: [
    "Enterprise focus only",
    "Higher pricing",
    "Complex setup",
    "Required training"
  ],
  features: [
    "Code modernization",
    "Legacy analysis",
    "Security scanning",
    "API generation",
    "Documentation creation",
    "Test automation",
    "Compliance checking"
  ],
  bestFor: [
    "Enterprise modernization",
    "Legacy systems",
    "Financial institutions",
    "Government projects"
  ],
  languages: [
    "COBOL",
    "Java",
    "Python",
    "JavaScript",
    "C++",
    "C#",
    "PL/I",
    "RPG"
  ],
  integrations: [
    "Eclipse",
    "VS Code",
    "IBM IDEs",
    "Jenkins"
  ],
  tags: ["Enterprise", "Modernization", "Legacy Code"],
  links: {
    website: "https://www.ibm.com/watsonx/code-assistant",
    documentation: "https://www.ibm.com/docs/watsonx-code-assistant",
    pricing: "https://www.ibm.com/watsonx/pricing"
  }
},
{
  id: "aws-code-catalyst",
  name: "AWS Code Catalyst",
  provider: "Amazon",
  description: "Unified AI-powered development environment for AWS cloud applications with automated coding and deployment",
  usageCount: 175000,
  rating: 4.4,
  releaseDate: "2023-11",
  pricing: {
    type: "Pay-per-use",
    hasFreeVersion: true,
    startingPrice: "Free tier available",
    details: [
      "Free tier with limits",
      "Pay for compute time",
      "Team pricing available",
      "Enterprise contracts"
    ]
  },
  strengths: [
    "AWS integration",
    "CI/CD automation",
    "Cloud-native focus",
    "Serverless expertise"
  ],
  limitations: [
    "AWS ecosystem only",
    "Usage-based costs",
    "Learning curve",
    "Cloud dependency"
  ],
  features: [
    "Project blueprints",
    "Code generation",
    "Automated testing",
    "CI/CD pipelines",
    "Infrastructure as code",
    "Security scanning",
    "Deploy automation"
  ],
  bestFor: [
    "AWS development",
    "Cloud applications",
    "Serverless apps",
    "DevOps teams"
  ],
  languages: [
    "Python",
    "JavaScript",
    "TypeScript",
    "Java",
    "Go",
    "C#",
    "Ruby",
    ".NET"
  ],
  integrations: [
    "AWS Console",
    "VS Code",
    "Git providers",
    "AWS services"
  ],
  tags: ["Cloud Development", "DevOps", "AWS"],
  links: {
    website: "https://aws.amazon.com/codecatalyst/",
    documentation: "https://docs.aws.amazon.com/codecatalyst/",
    pricing: "https://aws.amazon.com/codecatalyst/pricing/"
  }
},
{
  id: "mintlify-writer",
  name: "Mintlify Writer",
  provider: "Mintlify",
  description: "AI-powered documentation generator and code explanation tool with automatic API documentation features",
  usageCount: 125000,
  rating: 4.5,
  releaseDate: "2023-08",
  pricing: {
    type: "Freemium",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Free tier available",
      "Pro: $20/month",
      "Team: $15/user/month",
      "Enterprise custom pricing"
    ]
  },
  strengths: [
    "Documentation expertise",
    "API documentation",
    "Multi-language support",
    "Easy integration"
  ],
  limitations: [
    "Documentation focused",
    "Limited code generation",
    "API limits on free tier",
    "Premium features cost"
  ],
  features: [
    "Documentation generation",
    "Code explanation",
    "API documentation",
    "Comment generation",
    "Markdown support",
    "Style consistency",
    "Team collaboration"
  ],
  bestFor: [
    "Documentation teams",
    "API developers",
    "Open source projects",
    "Technical writing"
  ],
  languages: [
    "JavaScript",
    "Python",
    "Java",
    "Ruby",
    "Go",
    "PHP",
    "C++",
    "TypeScript"
  ],
  integrations: [
    "VS Code",
    "JetBrains IDEs",
    "GitHub",
    "GitLab"
  ],
  tags: ["Documentation", "API", "Technical Writing"],
  links: {
    website: "https://mintlify.com",
    documentation: "https://docs.mintlify.com",
    pricing: "https://mintlify.com/pricing"
  }
},
    {
  id: "lovable",
  name: "Lovable",
  provider: "Lovable (formerly GPT-Engineer)",
  description: "AI-powered software development tool that can build entire applications from natural language descriptions with advanced project planning capabilities",
  usageCount: 165000,
  rating: 4.5,
  releaseDate: "2024-01",
  pricing: {
    type: "Subscription",
    hasFreeVersion: true,
    startingPrice: "Free",
    details: [
      "Open source version available",
      "Pro: $29/month",
      "Team: $49/user/month",
      "Enterprise: Custom pricing"
    ]
  },
  strengths: [
    "Full project generation",
    "Natural language interface",
    "Project planning",
    "Open source foundation"
  ],
  limitations: [
    "Complex setups need refinement",
    "Requires clear specifications",
    "May need human oversight",
    "API costs for hosted version"
  ],
  features: [
    "Complete app generation",
    "Project specification",
    "Architecture planning",
    "Code documentation",
    "Test generation",
    "Step-by-step development",
    "Version control integration"
  ],
  bestFor: [
    "Rapid prototyping",
    "MVPs",
    "Project bootstrapping",
    "Small to medium projects"
  ],
  languages: [
    "Python",
    "JavaScript",
    "TypeScript",
    "HTML/CSS",
    "React",
    "Node.js",
    "SQL",
    "Docker"
  ],
  integrations: [
    "GitHub",
    "VS Code",
    "Terminal",
    "Custom IDE plugins"
  ],
  tags: ["Project Generation", "Natural Language", "Full-Stack"],
  links: {
    website: "https://www.lovable.dev",
    documentation: "https://docs.lovable.dev",
    pricing: "https://www.lovable.dev/pricing"
  }
}
  ]
};

function AIAgentsDirectory() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedLanguage, setSelectedLanguage] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  
  const allLanguages = useMemo(() => {
    return [...new Set(data.agents.flatMap(agent => agent.languages))].sort();
  }, []);

  const filteredAgents = useMemo(() => {
    return data.agents.filter(agent => {
      const matchesSearch = 
        agent.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        agent.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())) ||
        agent.languages.some(lang => lang.toLowerCase().includes(searchTerm.toLowerCase()));
      
      const matchesCategory = selectedCategory === 'all' || agent.tags.includes(selectedCategory);
      const matchesLanguage = selectedLanguage === 'all' || agent.languages.includes(selectedLanguage);
      
      return matchesSearch && matchesCategory && matchesLanguage;
    });
  }, [searchTerm, selectedCategory, selectedLanguage]);

  return (
    <div className="min-h-screen bg-gray-950 text-white p-8">
      {/* Header section remains the same */}
      <div className="max-w-6xl mx-auto text-center mb-12">
        <div className="flex justify-center mb-4">
          <div className="bg-gray-800 rounded-full p-4">
            <Code className="w-8 h-8" />
          </div>
        </div>
        <h1 className="text-5xl font-bold mb-4">AI Coding Assistants Directory</h1>
        <p className="text-xl text-gray-400 mb-4">
          Compare Top AI Development Tools
        </p>
        <div className="flex items-center justify-center gap-2 text-gray-500">
          <p>Last updated: {data.metadata.lastUpdated}</p>
          <span>•</span>
          <p>{data.metadata.totalAgents} tools compared</p>
        </div>
      </div>

      {/* Search and filters remain the same */}
      <div className="max-w-6xl mx-auto mb-8">
        <div className="relative flex items-center mb-6">
  <div className="relative flex-1">
    <input
      type="text"
      placeholder="Search by name, description, languages..."
      className="w-full bg-gray-900/50 border border-gray-800 rounded-xl py-4 px-6 pl-12 text-white focus:outline-none focus:border-gray-700"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
    <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500" size={20} />
  </div>
  <button
    onClick={() => setShowFilters(!showFilters)}
    className="ml-3 p-4 bg-gray-900/50 border border-gray-800 rounded-xl text-gray-500 hover:text-white hover:border-gray-700 transition-colors"
    aria-label="Toggle filters"
  >
    {showFilters ? <X size={20} /> : <Filter size={20} />}
  </button>
</div>

{/* Filters Panel */}
{showFilters && (
  <div className="bg-gray-900/50 border border-gray-800 rounded-xl p-6">
    {/* Categories */}
    <div className="mb-6">
      <h3 className="text-gray-400 text-sm mb-3">Categories</h3>
      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => setSelectedCategory('all')}
          className={`px-3 py-1.5 rounded-full text-sm transition-colors ${
            selectedCategory === 'all' 
              ? 'bg-blue-600 text-white' 
              : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700'
          }`}
        >
          All
        </button>
        {data.categories.map((category) => (
          <button
            key={category}
            onClick={() => setSelectedCategory(category)}
            className={`px-3 py-1.5 rounded-full text-sm transition-colors ${
              selectedCategory === category 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700'
            }`}
          >
            {category}
          </button>
        ))}
      </div>
    </div>

    {/* Languages */}
    <div>
      <h3 className="text-gray-400 text-sm mb-3">Programming Languages</h3>
      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => setSelectedLanguage('all')}
          className={`px-3 py-1.5 rounded-full text-sm transition-colors ${
            selectedLanguage === 'all' 
              ? 'bg-blue-600 text-white' 
              : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700'
          }`}
        >
          All
        </button>
        {allLanguages.map((language) => (
          <button
            key={language}
            onClick={() => setSelectedLanguage(language)}
            className={`px-3 py-1.5 rounded-full text-sm transition-colors ${
              selectedLanguage === language 
                ? 'bg-blue-600 text-white' 
                : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700'
            }`}
          >
            {language}
          </button>
        ))}
      </div>
    </div>
  </div>
)}
      </div>

      {/* Enhanced Cards Grid */}
      <div className="max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
        {filteredAgents.map((agent) => (
          <div key={agent.id} className="bg-gray-900 rounded-xl p-6 border border-gray-800 hover:border-gray-700 transition-all">
            {/* Header */}
            <div className="flex justify-between items-start mb-6">
              <div>
                <h3 className="text-2xl font-bold mb-1">{agent.name}</h3>
                <p className="text-gray-400 text-sm">by {agent.provider}</p>
              </div>
              <div className="flex flex-col items-end">
                <div className="flex items-center space-x-1 mb-1">
                  <span className="text-yellow-500 font-semibold">{agent.rating}</span>
                  <Star className="w-4 h-4 text-yellow-500 fill-current" />
                </div>
                <span className="text-xs text-gray-500">{agent.usageCount.toLocaleString()} users</span>
              </div>
            </div>
            
            {/* Description */}
            <p className="text-gray-300 text-sm mb-6">
              {agent.description}
            </p>

            {/* Best For */}
            <div className="mb-6">
              <h4 className="text-sm font-semibold mb-2 text-gray-300">Best For</h4>
              <div className="flex flex-wrap gap-2">
                {agent.bestFor.map((use, idx) => (
                  <span 
                    key={idx}
                    className="text-xs px-2 py-1 bg-blue-900/30 text-blue-400 rounded-full"
                  >
                    {use}
                  </span>
                ))}
              </div>
            </div>

            {/* Pricing */}
            <div className="mb-6 p-4 bg-gray-800 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium">Starting from</span>
                <span className="text-green-400 font-semibold">{agent.pricing.startingPrice}</span>
              </div>
              <div className="text-xs text-gray-400">
                {agent.pricing.details.map((detail, idx) => (
                  <div key={idx} className="flex items-center gap-1 mb-1">
                    <span>•</span>
                    <span>{detail}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Key Features */}
            <div className="mb-6">
              <h4 className="text-sm font-semibold mb-3 text-gray-300">Key Features</h4>
              <div className="grid grid-cols-2 gap-2">
                {agent.features.map((feature, idx) => (
                  <div 
                    key={idx}
                    className="flex items-center gap-2 text-xs text-gray-300"
                  >
                    <Zap className="w-3 h-3 text-blue-400" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Strengths & Limitations */}
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div>
                <h4 className="text-sm font-semibold mb-2 text-green-400">Strengths</h4>
                <ul className="text-xs space-y-1">
                  {agent.strengths.map((strength, idx) => (
                    <li key={idx} className="flex items-start gap-1 text-gray-300">
                      <span className="text-green-400 mt-1">+</span>
                      <span>{strength}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h4 className="text-sm font-semibold mb-2 text-yellow-400">Limitations</h4>
                <ul className="text-xs space-y-1">
                  {agent.limitations.map((limitation, idx) => (
                    <li key={idx} className="flex items-start gap-1 text-gray-300">
                      <span className="text-yellow-400 mt-1">-</span>
                      <span>{limitation}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Integrations */}
            <div className="mb-6">
              <h4 className="text-sm font-semibold mb-2 text-gray-300">Supported IDEs & Integrations</h4>
              <div className="flex flex-wrap gap-2">
                {agent.integrations.map((integration, idx) => (
                  <span 
                    key={idx}
                   className="text-xs px-2 py-1 bg-gray-800 text-gray-300 rounded-full flex items-center gap-1"
                  >
                    <Globe className="w-3 h-3" />
                    {integration}
                  </span>
                ))}
              </div>
            </div>

            {/* Languages */}
            <div className="mb-6">
              <h4 className="text-sm font-semibold mb-2 text-gray-300">Supported Languages</h4>
              <div className="flex flex-wrap gap-2">
                {agent.languages.map((lang, idx) => (
                  <span 
                    key={idx}
                    className="text-xs px-2 py-1 bg-gray-800 text-gray-300 rounded-full flex items-center gap-1"
                  >
                    <Code className="w-3 h-3" />
                    {lang}
                  </span>
                ))}
              </div>
            </div>

            {/* Additional Info */}
            <div className="flex items-center justify-between text-xs text-gray-500 mb-6">
              <span>Released: {agent.releaseDate}</span>
              <span className="flex items-center gap-1">
                <Server className="w-3 h-3" />
                {agent.pricing.type}
              </span>
              {agent.pricing.hasFreeVersion && (
                <span className="flex items-center gap-1 text-green-400">
                  <Lock className="w-3 h-3" />
                  Free tier available
                </span>
              )}
            </div>

            {/* Links */}
            <div className="flex gap-3 border-t border-gray-800 pt-4">
              {Object.entries(agent.links).map(([key, url]) => (
                <a
                  key={key}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-1 text-xs text-blue-400 hover:text-blue-300 transition-colors"
                >
                  {key}
                  <ExternalLink size={12} />
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Results count */}
      <div className="max-w-6xl mx-auto mt-6">
        <p className="text-gray-400 text-sm">
          Showing {filteredAgents.length} {filteredAgents.length === 1 ? 'tool' : 'tools'}
        </p>
      </div>
    </div>
  );
}

export default AIAgentsDirectory;
