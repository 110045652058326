import React from 'react';
import { Helmet } from 'react-helmet';

const SEOHead = () => {
  return (
    <Helmet>
      {/* Essential Meta Tags */}
      <title>Top AI Dev Tools - Compare AI Coding Assistants and Development Tools 2024</title>
      <meta name="description" content="Discover and compare the best AI coding assistants, development tools, and programming aids. Comprehensive comparison of GitHub Copilot, Claude, CodeWhisperer, and more for 2024." />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />
      
      {/* Enhanced Keywords */}
      <meta name="keywords" content="AI coding tools, AI development tools, GitHub Copilot, Claude AI, Amazon CodeWhisperer, Tabnine, Codeium, Cursor, AI programming assistant, code completion, AI tools comparison, developer tools 2024, coding assistant, AI code generation, AI pair programming" />
      
      {/* Additional Meta Tags */}
      <meta name="author" content="Top AI Dev Tools" />
      <meta name="robots" content="index, follow, max-image-preview:large" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="1 days" />
      <meta name="generator" content="react" />
      
      {/* Open Graph / Facebook - Enhanced */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://topaidevtools.com/" />
      <meta property="og:title" content="Top AI Dev Tools - Compare Best AI Coding Assistants 2024" />
      <meta property="og:description" content="Find and compare the best AI-powered development tools and coding assistants. Detailed comparisons of GitHub Copilot, Claude, CodeWhisperer, and more." />
      <meta property="og:image" content="https://topaidevtools.com/logo512.png" />
      <meta property="og:site_name" content="Top AI Dev Tools" />
      <meta property="og:locale" content="en_US" />
      
      {/* Twitter - Enhanced */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content="https://topaidevtools.com/" />
      <meta name="twitter:title" content="Top AI Dev Tools - Compare Best AI Coding Assistants 2024" />
      <meta name="twitter:description" content="Find and compare the best AI-powered development tools and coding assistants. Detailed comparisons of GitHub Copilot, Claude, CodeWhisperer, and more." />
      <meta name="twitter:image" content="https://topaidevtools.com/logo512.png" />
      <meta name="twitter:creator" content="@topaidevtools" /> {/* If you have a Twitter account */}
      
      {/* Canonical URL */}
      <link rel="canonical" href="https://topaidevtools.com/" />

      {/* Additional Link Tags */}
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/logo192.png" />
      
      {/* Schema.org Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "Top AI Dev Tools",
          "url": "https://topaidevtools.com",
          "description": "Comprehensive directory and comparison of AI coding assistants and development tools.",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://topaidevtools.com/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        })}
      </script>
    </Helmet>
  );
};

export default SEOHead;
